var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen container mx-auto min-contents" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "grid grid-cols-12 gap-6 sm:grid-cols-1 sm:gap-1" },
      [
        _vm._m(1),
        _c("div", { staticClass: "help-sumally" }, [
          _c(
            "div",
            { staticClass: "help-index border-2 border-base-yellow mb-8 p-4" },
            [
              _c(
                "h4",
                { staticClass: "font-bold text-xl text-left mt-4 mb-2 ml-4" },
                [_vm._v("See more help")]
              ),
              _c(
                "div",
                { staticClass: "p-4" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/" },
                    },
                    [_vm._v(" Guideline ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/account" },
                    },
                    [_vm._v(" Create your account ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/quote" },
                    },
                    [_vm._v(" Request Quote ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/quote-save-condition" },
                    },
                    [_vm._v(" Saved condition function for Request Quote ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/quote-proposal" },
                    },
                    [
                      _vm._v(
                        " Request Quote from our proposal(recommend movie list) "
                      ),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/quote-check" },
                    },
                    [_vm._v(" Check Quote ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/delivery" },
                    },
                    [_vm._v(" Delivery ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/email" },
                    },
                    [_vm._v(" Don't recieved a reset email? ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/password" },
                    },
                    [_vm._v(" Lost Password? ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full rounded overflow-hidden my-4" }, [
      _c("div", { staticClass: "px-6 py-4" }, [
        _c("h2", { staticClass: "text-center font-bold text-3xl" }, [
          _vm._v("Request Quote"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-span-9 flex flex-col justify-center sm:col-span-1" },
      [
        _c(
          "div",
          {
            staticClass: "w-full rounded overflow-hidden shadow-lg mb-6",
            attrs: { id: "help-vod-02" },
          },
          [
            _c("div", { staticClass: "px-6 pb-12" }, [
              _c("ul", { staticClass: "text-base" }, [
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("1.")]
                    ),
                    _vm._v(
                      " Login with your account. then You could serach films on our platform."
                    ),
                    _c("br"),
                  ]),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("2.")]
                    ),
                    _vm._v(" Search and select films you would like to buy. "),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/vod/02-01.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("3.")]
                    ),
                    _vm._v(" Click Request for Quote. "),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/vod/02-04.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("4.")]
                    ),
                    _vm._v(" The item is added to your cart."),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      ' You can find two button: "keep looking around" and "go to cart".'
                    ),
                    _c("br"),
                    _vm._v(
                      ' Click "go to cart", if you would like to move to cart page. '
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/vod/02-05.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("5.")]
                    ),
                    _vm._v(
                      " Enter terms and delivery asset information. We have 5 secltions below."
                    ),
                    _c("br"),
                    _vm._v(
                      " saved conditions and date, conditions, main movie & trailers, artwork and documents. "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/vod/02-06.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("6.")]
                    ),
                    _vm._v(" Enter all information and click Submit. "),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/vod/02-09.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("7.")]
                    ),
                    _vm._v(
                      " Something wrong? Please follow the instructions/error message(red color text) and enter the rest of information then try again. If it is still not working well, feel free to ask our responsible anytime. "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/vod/02-10.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("8.")]
                    ),
                    _vm._v(" That's all. Thank you for taking you time!"),
                    _c("br"),
                    _vm._v(
                      " After contents holder check your request, they will inform you back on Filmination platform. "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/vod/02-11.jpg"),
                      alt: "",
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }